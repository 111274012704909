<div class="container-fluid">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link" routerLink="/apps">All Applications</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/version">Compatibility Lookup</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/history">User History</a>
      </li>
    </ul>

  <div class="row" style="padding-top: 10px;"></div>
    <div *ngIf="app == null; else elseBlock" >
    </div>
    <ng-template #elseBlock>
      <div class="fade-in">
        <table class="table table-borderless">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">App Version</th>
            <th scope="col">N4 Compatibility</th>
            <th scope="col">Total Downloads</th>
          </tr>
          <tbody>
          <tr>
            <td>{{ appInfo._appName }}</td>
            <td>{{ appInfo._appVersion }}</td>
            <td>{{ appInfo._n4Compatibility }}</td>
            <td>{{ appInfo._downloadCount }}</td>
          </tr>
          </tbody>
        </table>

      </div>
    </ng-template>
  </div>
