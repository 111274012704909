import { Component, OnInit, ChangeDetectorRef, Inject, ViewChild } from '@angular/core';
import {
  InitializingState,
  NavisCoreService,
  BaseConfig,
  BaseConfigService,
  DisplayOptionsConfigService,
  DisplayConfig,
} from '@navis/core';
import { NcsNavComponent } from '@navis/design';
import { MatSidenav } from '@angular/material/sidenav';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'n4-core-layout',
  templateUrl: './core-layout.component.html',
  styleUrls: ['./core-layout.component.scss']
})
export class CoreLayoutComponent implements OnInit {

  @ViewChild(MatSidenav) sideNav: MatSidenav;
  @ViewChild(NcsNavComponent) ncsNav: NcsNavComponent;

  public config: DisplayConfig;
  public baseConfig: BaseConfig;
  public canShowApp: boolean = false;

  public devMode: boolean = true;

  public sideNavWidth: number = 230;
  public hideToggle: boolean = false;
  public smallNav: boolean = false;
  public moduleName: string;

  public mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  constructor(@Inject(DisplayOptionsConfigService) private _config,
              @Inject(BaseConfigService) private _baseConfig: BaseConfig,
              private _navisCoreService: NavisCoreService,
              private _changeDetectorRef: ChangeDetectorRef,
              private _media: MediaMatcher) {
    this.mobileQuery = _media.matchMedia('(max-width: 960px)');
    this._mobileQueryListener = () => _changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.config = _config;
    this.baseConfig = _baseConfig;
    this.devMode = this.baseConfig && this.baseConfig.ENV && this.baseConfig.ENV === 'DEV';
  }

  ngOnInit() {
    this._navisCoreService.showSelectAccessRoleDialog.subscribe((data: boolean) => {
      if (data === true) {
        this.disableLoadingScreen(true);
      } else {
        this.canShowApp = false;
      }
    });

    this._navisCoreService.showSelectAccountDialog.subscribe((data: boolean) => {
      if (data === true) {
        this.disableLoadingScreen(true);
      } else {
        this.canShowApp = false;
      }
    });

    this._navisCoreService.canActivateProtectedRoutes$.subscribe((can: boolean) => {
      if (can === true) {
        this.disableLoadingScreen(true);
      } else {
        this.canShowApp = false;
      }
    });

    this._navisCoreService.authState.subscribe((state: InitializingState) => {
      if (state.showSpinner === false && state.errorCode !== -1) {
        this.disableLoadingScreen(false);
      }
    });

    this._navisCoreService.selectedModulName.subscribe((data: string) => {
      this.moduleName = data;
    });
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy() {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  public sidebarClick() {
    // Event fired to trigger resizing of highcharts charts
    window.dispatchEvent(new Event('resize'));
  }

  public onLinkClicked() {
    if (this.mobileQuery.matches) {
      this.sideNav.close();
    }
  }

  public toggleSidebar() {
    if (this.mobileQuery.matches) {
      this.hideToggle = false;
      this.smallNav = false;
      this.sideNavWidth = 230;

      this.sideNav.toggle();
    } else {
      if (this.sideNavWidth === 230) {
        this.hideToggle = true;
        this.smallNav = true;
        this.sideNavWidth = 56;

        if (this.ncsNav) {
          this.ncsNav.closeAllPanels();
        }
      } else {
        this.hideToggle = false;
        this.smallNav = false;
        this.sideNavWidth = 230;
      }
    }

    setTimeout(() => {
      this.sidebarClick();
    }, 405);
  }

  private disableLoadingScreen(shouldSeeApp: boolean) {
    const spinner = document.getElementById('navis-spinner');

    if (spinner) {
      this.canShowApp = shouldSeeApp;
      setTimeout(() => {
        spinner.classList.add('disabled');

        setTimeout(() => {
          spinner.remove();
        }, 1000);
      }, 500);
    } else {
      this.canShowApp = shouldSeeApp;
      this._changeDetectorRef.markForCheck();
    }
  }
}
