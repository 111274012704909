import { Component, OnInit } from '@angular/core';
import {AuthenticationService, HttpErrorHandlerService} from '@navis/core';
import {ApplicationService} from '../../../application.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserHistoryEntry} from "../../../model/user-history-entry/user-history-entry";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-app-history',
  templateUrl: './app-history.component.html',
  styleUrls: ['./app-history.component.scss']
})
export class AppHistoryComponent implements OnInit {

  items: UserHistoryEntry[];
  pageOfItems: Array<any>;
  private routeSub: Subscription;
  appId: string;
  appName: string = '';
  appVersion: string = '';

  constructor(
    private authService: AuthenticationService,
    private appService: ApplicationService,
    private route: ActivatedRoute,
    private router: Router,
    private errorHandlerService: HttpErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(params => {
      this.appId = params.appId;
    });
    this.appService.getHistoryByAppId(this.appId).subscribe(data => {
      if (data.length === 0) {
        window.alert('No history available for this application.');
        this.refreshHistory();
      } else {
        this.appName = data[0]._appName;
        this.appVersion = data[0]._appVersion;
        this.items = data;
      }
    }, error => {
      this.errorHandlerService.handleError(error);
    });
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    console.log(pageOfItems[0]);
    this.pageOfItems = pageOfItems;
  }

  refreshHistory() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['/history']);
    });
  }

}
