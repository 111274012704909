import { Component, OnInit } from '@angular/core';
import {UserHistoryEntry} from '../../../model/user-history-entry/user-history-entry';
import {AuthenticationService, HttpErrorHandlerService} from '@navis/core';
import {ApplicationService} from '../../../application.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-action-history',
  templateUrl: './action-history.component.html',
  styleUrls: ['./action-history.component.scss']
})
export class ActionHistoryComponent implements OnInit {

  items: UserHistoryEntry[];
  pageOfItems: Array<any>;
  private routeSub: Subscription;
  action: string;

  constructor(
    private authService: AuthenticationService,
    private appService: ApplicationService,
    private route: ActivatedRoute,
    private router: Router,
    private errorHandlerService: HttpErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(params => {
      this.action = params.action;
    });
    this.appService.getHistoryByAction(this.action).subscribe(data => {
      if (data.length === 0) {
        window.alert('History of type ' + this.action + ' not found.');
        this.router.navigate(['/history']);
      } else {
        this.items = data;
      }
    }, error => {
      this.errorHandlerService.handleError(error);
    });
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    console.log(pageOfItems[0]);
    this.pageOfItems = pageOfItems;
  }

  refresh() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['/history']);
    });
  }
}
