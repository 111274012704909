<div class="card my-5">
  <div class="card-body">
    <div *ngIf="role != 'Administrator'; else elseBlock">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link active" routerLink="/apps">My Applications</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/moreapps">Other Applications</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/version">Compatibility Lookup</a>
        </li>
      </ul>
    </div>
    <ng-template #elseBlock>
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link active" routerLink="/apps">All Applications</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/manage">Manage Applications</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/version">Compatibility Lookup</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/history">User History</a>
        </li>
      </ul>
    </ng-template>
    <button type="submit" name="submitButton" (click)="clickEvent()" class="btn btn-outline-dark">Download</button>
  </div>
</div>

