import { Component, OnInit } from '@angular/core';
import { AuthenticationService, LogglyService } from '@navis/core';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title: string;

  constructor( private _auth: AuthenticationService,
               private _loggly: LogglyService,
               private _router: Router ) {
  }

  ngOnInit(): void {
    this._router.events.subscribe(
      ( data: any ) => {
        if (data instanceof NavigationStart) {
          const url = data.url.substring(0, 100);
          this._loggly.debug('Route changed: ' + url, {url: url});
        }
      });

    this._loggly.info('App initialized.');
    this._auth.runInitialLoginSequence();
  }
}
