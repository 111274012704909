<div class="container-fluid">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link" routerLink="/apps">All Applications</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/version">Compatibility Lookup</a>
      </li>
      <li class="nav-item">
        <a class="nav-link active" routerLink="/history" style="border-bottom: 2px solid #7AC142;">User History</a>
      </li>
    </ul>

  <div class="row" style="padding-top: 10px;"></div>
    <h5 *ngIf="entries.length != 0">
      History for {{ entries[0]._userEmail }}
    </h5>
    <table class="table table-borderless table-hover fade-in" *ngIf="entries.length != 0">
      <tr>
        <th scope="col">Action</th>
        <th scope="col">Application Name</th>
        <th scope="col">Application Version</th>
        <th scope="col">Date/Time</th>
      </tr>
      <tbody>
      <tr *ngFor="let entry of entries">
        <td>{{ entry._action }}</td>
        <td *ngIf="entry._action != 'Delete'; else elseBlock">
          <a routerLink="/apps/{{ entry._appId }}">{{ entry._appName }}</a>
        </td>
        <ng-template #elseBlock>
          <td>
            {{ entry._appName }}
          </td>
        </ng-template>
        <td>{{ entry._appVersion }}</td>
        <td>{{ entry._dateTime }}</td>
      </tr>
      </tbody>
    </table>
</div>
