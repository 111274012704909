<div class="container-fluid">
  <div *ngIf="role != 'Administrator'; else elseBlock">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link active" routerLink="/apps" style="border-bottom: 2px solid #7AC142;">My Applications</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/moreapps">Other Applications</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/version">Compatibility Lookup</a>
      </li>
    </ul>
    <div>
      <div class="row" style="padding-top: 10px"></div>
      <table class="table table-hover table-borderless fade-in" *ngIf="customerApps != null">
        <tr>
          <th scope="col" class="icon-column">Name</th>
          <th scope="col">Minimum Supported N4 Version</th>
          <th scope="col">Action</th>
        </tr>
        <tbody>
        <tr *ngFor="let app of customerApps">
          <td class="icon-column">
            <svg *ngIf="app === 'Control Room UI'"
                 class="icon-styles"
                 viewBox="0 0 50 50"
                 width="50"
                 height="50"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M30 14h5a1 1 0 011 1v6a1 1 0 01-1 1h-5a1 1 0 01-1-1v-6a1 1 0 011-1zm0 7h5v-6h-5v6zm0 2h5a1 1 0 011 1v4a1 1 0 01-1 1h-5a1 1 0 01-1-1v-4a1 1 0 011-1zm0 5h5v-4h-5v4zM19 14.333A4.672 4.672 0 0014.333 19c0 3.5 4.667 8.667 4.667 8.667S23.667 22.5 23.667 19A4.672 4.672 0 0019 14.333zm0 1.334c1.833 0 3.333 1.5 3.333 3.333 0 1.85-1.5 3.333-3.333 3.333A3.332 3.332 0 0115.667 19c0-1.833 1.5-3.333 3.333-3.333z" fill="white"/>
              <path d="M37.09 10H10.91C9.31 10 8 11.305 8 12.9v17.4c0 1.595 1.31 2.9 2.91 2.9h10.18v2.9h-1.908a1 1 0 00-1 1v.9h11.636v-.9a1 1 0 00-1-1H26.91v-2.9h10.182c1.6 0 2.909-1.305 2.909-2.9V12.9c0-1.595-1.31-2.9-2.91-2.9zm0 20.3H10.91V12.9h26.18v17.4z" fill="white"/>
            </svg>
            <svg *ngIf="app === 'NVMT'"
                 class="icon-styles"
                 viewBox="0 0 50 50"
                 width="50"
                 height="50"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M43.435 8c1.116 0 2.021.905 2.021 2.022v26.956A2.022 2.022 0 0143.435 39H5.022A2.022 2.022 0 013 36.978V10.022C3 8.905 3.905 8 5.022 8h38.413zM42 10H6v26.978h36.087L42 10z" fill="white"/>
              <path d="M13.188 31.188c.655 0 1.187.531 1.187 1.187v1.188a1.188 1.188 0 01-2.375 0v-1.188c0-.656.532-1.188 1.188-1.188zM23.28 11a2.969 2.969 0 012.91 3.565l3.622-.002c.655 0 1.187.531 1.187 1.187v13.063a1.188 1.188 0 01-2.375 0V16.937h-14.25v11.875a1.188 1.188 0 01-2.375 0V15.75c0-.656.532-1.188 1.188-1.188l3.622.003A2.969 2.969 0 0119.719 11h3.562zm-.593 7.125a3.563 3.563 0 013.562 3.563v2.375a3.563 3.563 0 01-3.563 3.562h-2.375a3.563 3.563 0 01-3.562-3.563v-2.375a3.563 3.563 0 013.563-3.562h2.375zm.593 1.357h-3.56c-.914 0-1.667.688-1.77 1.574l-.012.207v3.224c0 .913.688 1.666 1.574 1.769l.208.012h3.562c.914 0 1.667-.688 1.77-1.574l.012-.207v-3.224c0-.983-.798-1.78-1.782-1.78zm0-7.294h-3.56a1.781 1.781 0 00-1.68 2.376h6.921a1.781 1.781 0 00-1.68-2.377z" fill="white"/>
              <path d="M34.667 26.417v.666h2l2 2.667v3.333H38a2 2 0 11-4 0h-2a2 2 0 11-4 0 2 2 0 11-4 0V31.75h.51a1.995 1.995 0 011.49-.667c.592 0 1.124.258 1.49.667h1.02a1.995 1.995 0 011.49-.667c.592 0 1.124.258 1.49.667h1.843v-5.333h1.334zM26 32.083a1 1 0 100 2 1 1 0 100-2zm10 0a1 1 0 100 2 1 1 0 100-2zm-6 0a1 1 0 100 2 1 1 0 100-2zm6.333-4h-1.666v1.667h2.973l-1.307-1.667z" fill="white"/>
            </svg>
            {{ app }}
          </td>
          <td>
            0.0.0
          </td>
          <td>
            <a routerLink="/version">Find Version</a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <ng-template #elseBlock>
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link active" routerLink="/apps" style="border-bottom: 2px solid #7AC142;">All Applications</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/version">Compatibility Lookup</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/history">User History</a>
      </li>
    </ul>

    <div class="row" style="padding-top: 10px"></div>
    <div class="btn-group-toggle" role="group">
      <button type="button" style="margin: 10px" data-bs-toggle="button" class="btn btn-outline-dark fade-in" (click)="addButtonClick()">
        Add Application
      </button>
      <button *ngIf="getShowActionsState()" type="button" style="margin: 10px" data-bs-toggle="button" class="btn btn-outline-dark fade-in" (click)="editButtonClick()">
        Edit Application
      </button>
      <button *ngIf="getShowActionsState()" type="button" style="margin: 10px" data-bs-toggle="button" class="btn btn-outline-dark fade-in" (click)="deleteButtonClick()">
        Delete Application
      </button>
    </div>
    <table *ngIf="apps.length > 0" class="fade-in table table-hover table-borderless">
      <tbody>
      <tr>
        <th scope="col">Select</th>
        <th scope="col">Name</th>
        <th scope="col">App Version</th>
        <th scope="col">Build Date</th>
        <th scope="col">Supported N4 Versions</th>
        <th scope="col">Total Downloads</th>
        <th scope="col" style="width: 40%">Release Notes</th>
      </tr>
      <tr *ngFor="let app of apps">
        <td>
          <mat-radio-group>
            <mat-radio-button [checked]="selectedApp._id == app._id" id="{{ app._id }}" [value]=app._id (click)="onRadioSelect(app)">
            </mat-radio-button>
          </mat-radio-group>
        </td>
        <td>
          {{ app._appName }}
        </td>
        <td>{{ app._appVersion }}</td>
        <td>{{ app._buildDate }}</td>
        <td>
          <div *ngFor="let n4Version of app._n4Compatibility">
            {{ n4Version }}
          </div>
        </td>
        <td>{{ app._downloadCount }}</td>
        <td style="width: 40%">
          {{ app._releaseNotes }}
        </td>
      </tr>
      </tbody>
    </table>
  </ng-template>
</div>


