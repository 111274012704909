import { Component, OnInit } from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthenticationService, HttpErrorHandlerService} from '@navis/core';
import {ApplicationService} from '../../application.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Application} from '../../model/application/application';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {

  private routeSub: Subscription;
  id: string;
  app: Application;
  role: string;

  constructor(
    private authService: AuthenticationService,
    private appService: ApplicationService,
    private route: ActivatedRoute,
    private router: Router,
    private errorHandler: HttpErrorHandlerService,
  ) { }

  ngOnInit(): void {
    this.role = this.authService.accessRoles[0].name;
    this.routeSub = this.route.params.subscribe(params => {
      this.id = params.id;
    });
    this.appService.findById(this.id).subscribe(data => {
      this.app = data;
    }, error => {
      if (error.status === 404) {
        window.alert('Application not found.');
        this.router.navigate(['/apps']);
      } else {
        this.errorHandler.handleError(error);
      }
    });
  }

  clickEvent() {
    this.appService.downloadAppById(this.id).subscribe((response) => {
      let file = new Blob([response], {type: 'application/zip'});
      let url = window.URL.createObjectURL(file);
      let anchor = document.createElement('a');
      anchor.download = this.app._fileName;
      anchor.href = url;
      anchor.click();
    }, error => {
      if (error.status === 401) {
        window.alert('User does not have valid license to this application.');
      } else {
        this.errorHandler.handleError(error);
      }
    });
  }
}
