<div class="container-fluid">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link" routerLink="/apps">All Applications</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/version">Compatibility Lookup</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/history">User History</a>
      </li>
    </ul>
  <div class="row" style="padding-top: 10px"></div>
  <div class="fade-in" style="padding-left: 2%">
    <h5>
      Add Application
    </h5>
    <div class="row" style="padding-top: 10px"></div>
    <div>
      <div class="row">
        <div class="form-group col-md-4">
          <label>
            File:
            <input id="file-upload-input" type="file" formControlName="uploadFile" (change)="inputFile($event)"/>
          </label>
        </div>
      </div>
      <button type="submit" name="submitButton" value="submitButton"  class="btn btn-outline-dark"
              (click)="onSubmit()">Add App</button>
    </div>
  </div>
  <div *ngIf="showSpinner" id="navis-spinner" class="navis-spinner">
    <div></div>
  </div>
  </div>
