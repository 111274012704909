<div class="container-fluid">
  <div>
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link" routerLink="/apps">All Applications</a>
      </li>
      <li class="nav-item">
        <a class="nav-link active" routerLink="/manage" style="border-bottom: 2px solid #7AC142;">Manage Applications</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/version">Compatibility Lookup</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/history">User History</a>
      </li>
    </ul>
    <div class="row" style="padding-top:10px;"></div>
    <div class="fade-in">
      <div style="padding: 10px;">
        <a routerLink="/manage/add" class="btn btn-outline-dark" role="button" aria-disabled="true">Add Application</a>
      </div>
      <div style="padding: 10px;">
        <a routerLink="/manage/delete" class="btn btn-outline-dark" role="button" aria-disabled="true">Delete Application</a>
      </div>
      <div style="padding: 10px;">
        <a routerLink="/manage/edit" class="btn btn-outline-dark" role="button" aria-disabled="true">Edit Application</a>
      </div>
    </div>
</div>
  </div>

