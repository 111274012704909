import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AppListComponent} from './common-components/app-list/app-list.component';
import {ViewAppInfoComponent} from './admin-components/view-app-info/view-app-info.component';
import {VersionQueryComponent} from './common-components/version-query/version-query.component';
import {AdditionalAppsComponent} from './customer-components/additional-apps/additional-apps.component';
import {ManageAppsComponent} from './admin-components/manage-apps/manage-apps.component';
import {HistoryComponent} from './admin-components/history/all-history/history.component';
import {UserHistoryComponent} from './admin-components/history/user-history/user-history.component';
import {AddAppComponent} from './admin-components/add-app/add-app.component';
import {DeleteAppComponent} from './admin-components/delete-app/delete-app.component';
import {EditAppComponent} from './admin-components/edit-app/edit-app.component';
import {DownloadComponent} from './common-components/download/download.component';
import {ActionHistoryComponent} from './admin-components/history/action-history/action-history.component';
import {AppHistoryComponent} from './admin-components/history/app-history/app-history.component';

const routes: Routes = [
  { path: 'apps', component: AppListComponent },
  { path: 'apps/:id', component: ViewAppInfoComponent },
  { path: 'version', component: VersionQueryComponent },
  { path: 'moreapps', component: AdditionalAppsComponent },
  { path: 'manage', component: ManageAppsComponent },
  { path: 'history', component: HistoryComponent},
  { path: 'history/:id', component: UserHistoryComponent},
  { path: 'manage/add', component: AddAppComponent },
  { path: 'manage/delete', component: DeleteAppComponent },
  { path: 'manage/edit/:appid', component: EditAppComponent },
  { path: 'download/:id', component: DownloadComponent },
  { path: 'history/action/:action', component: ActionHistoryComponent},
  { path: 'history/app/:appId', component: AppHistoryComponent},
  // Route guard w/ Features
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
