<ncs-error-screen *ngIf="!canShowApp"></ncs-error-screen>

<ng-container *ngIf="canShowApp">
    <ncs-root-header>
        <div class="ncs-root-app-name" style="background-color: white">
<!--          *ngIf="!mobileQuery.matches"-->
<!--          [style.width.px]="sideNavWidth"-->
          <div>
            <span>
            <img class="img-responsive navis-logo" src="assets/images/navis-llc-squarelogo.png">
<!--            <h1 class="app-name">-->
<!--              N4 MarketPlace-->
<!--            </h1>-->
          </span>
          </div>
            {{smallNav ? config.appNameShort : config.appName}}
        </div>

        <div class="ncs-root-module-name mr-auto">
         {{moduleName}}
        </div>

      <ncs-user-account-menu class="ml-2"></ncs-user-account-menu>
        <ncs-access-role-menu class="ml-2"></ncs-access-role-menu>
        <ncs-release-notes-menu *ngIf="baseConfig.UsesReleaseNotesIo" class="ml-2"></ncs-release-notes-menu>
        <ncs-app-menu class="ml-2"></ncs-app-menu>
        <ncs-user-menu class="ml-2"></ncs-user-menu>

<!--      NcsThemeSwitcherModule is not working correctly in UI, omit it for now. -->
<!--        <ng-container *ngIf="devMode">-->
<!--            <mat-divider [vertical]="true" class="h-50 ml-2 mr-2"></mat-divider>-->
<!--            <button mat-icon-button [matMenuTriggerFor]="settingsMenu">-->
<!--                <i class="fas fa-cog dev-theme-button"></i>-->
<!--            </button>-->
<!--            <mat-menu #settingsMenu="matMenu">-->
<!--                <ncs-theme-switcher class="mt-1"></ncs-theme-switcher>-->
<!--            </mat-menu>-->
<!--        </ng-container>-->
    </ncs-root-header>


        <div class="main-layout-container">
            <ng-content></ng-content>
        </div>
</ng-container>
