import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, } from 'rxjs';
import {Application} from './model/application/application';
import {AuthenticationService, IdentityService} from '@navis/core';
import {UserHistoryEntry} from './model/user-history-entry/user-history-entry';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  // See environment.ts for http endpoint definitions
  private readonly baseUrl = environment.backendApplicationBaseUrl;
  private readonly appsUrl = this.baseUrl + environment.listApps;
  private readonly viewAppInfoUrl = this.baseUrl + environment.findAppById;
  private readonly  versionQueryUrl = this.baseUrl + environment.versionQuery;
  private readonly newAppUrl = this.baseUrl + environment.addApp;
  private readonly findAppUrl = this.baseUrl + environment.findApp;
  private readonly downloadAppUrl = this.baseUrl + environment.downloadById;
  private readonly allHistoryUrl = this.baseUrl + environment.allHistory;
  private token: string;
  private userUID: string;
  private headers: HttpHeaders;
  private accessRoleUid: string;

  constructor(private http: HttpClient, private authService: AuthenticationService,
              private identityService: IdentityService) {
    // this.appsUrl = 'http://localhost:8080/apps';
    // this.viewAppInfoUrl = 'http://localhost:8080/app/';
    // this.versionQueryUrl = 'http://localhost:8080/version';
    // this.newAppUrl = 'http://localhost:8080/app';
    // this.findAppUrl = 'http://localhost:8080/app';
    // this.downloadAppUrl = 'http://localhost:8080/download/';
    // this.allHistoryUrl = 'http://localhost:8080/history';
  }

  public findAllAdminApps(): Observable<Application[]> {
    this.setTokenAndAccessRoleUid();
    return this.http.get<Application[]>(this.appsUrl, {headers: this.headers});
  }

  public findAllCustomerApps(): Observable<string[]> {
    this.setTokenAndAccessRoleUid();
    return this.http.get<string[]>(this.appsUrl, {headers: this.headers});
  }

  public findById(id: string): Observable<Application> {
    this.setTokenAndAccessRoleUid();
    return this.http.get<Application>(this.viewAppInfoUrl.concat(id), {headers: this.headers});
  }

  public versionSearch(appName: string, n4Version: string): Observable<Application> {
    this.setTokenAndAccessRoleUid();
    let requestParams = new HttpParams()
      .set('inAppName', appName)
      .set('inN4Version', n4Version);
    return this.http.get<Application>(this.versionQueryUrl, {headers: this.headers, params: requestParams});
  }

  public addApp(formData: FormData): Observable<any> {
    this.setTokenAndAccessRoleUid();
    return this.http.post<any>(this.newAppUrl, formData, {headers: this.headers, responseType : 'arraybuffer' as 'json'});
  }

  public deleteApp(id: string): Observable<void> {
    this.setTokenAndAccessRoleUid();
    return this.http.delete<void>(this.viewAppInfoUrl.concat(id), { headers: this.headers});
  }

  public editAppName(id: string, inAppName: string): Observable<Application> {
    this.setTokenAndAccessRoleUid();
    const body = { inAppName };
    return this.http.put<Application>(this.viewAppInfoUrl.concat(id).concat('/appname'), body, {headers: this.headers});
  }

  public editAppVersion(id: string, inAppVersion: string): Observable<Application> {
    this.setTokenAndAccessRoleUid();
    const body = {inAppVersion};
    return this.http.put<Application>(this.viewAppInfoUrl.concat(id).concat('/appversion'), body, {headers: this.headers});
  }

  public editReleaseNotes(id: string, inReleaseNotes: string): Observable<Application> {
    this.setTokenAndAccessRoleUid();
    const body = {inReleaseNotes};
    return this.http.put<Application>(this.viewAppInfoUrl.concat(id).concat('/releasenotes'), body, {headers: this.headers});
  }

  public addN4Compatibility(id: string, inNewCompatibility): Observable<Application> {
    this.setTokenAndAccessRoleUid();
    const body = {inNewCompatibility};
    return this.http.put<Application>(this.viewAppInfoUrl.concat(id).concat('/compatibility'), body, {headers: this.headers});
  }

  public deleteN4Compatibility(id: string, inDeleteCompatibility): Observable<Application> {
    this.setTokenAndAccessRoleUid();
    let requestParams = new HttpParams()
      .set('inDeleteCompatibility', inDeleteCompatibility);
    return this.http.delete<Application>(this.viewAppInfoUrl.concat(id).concat('/compatibility'),
      {headers: this.headers, params: requestParams});
  }

  // Return the id of the found app
  public findApp(appName: string, appVersion: string): Observable<Application> {
    this.setTokenAndAccessRoleUid();
    let requestParams = new HttpParams()
      .set('inAppName', appName)
      .set('inAppVersion', appVersion);
    return this.http.get<Application>(this.findAppUrl, {headers: this.headers, params: requestParams, responseType: 'json'});
  }

  public downloadAppById(appId: string): Observable<any> {
    this.setTokenAndAccessRoleUid();
    return this.http.get<any>(this.downloadAppUrl.concat(appId), { headers: this.headers, responseType : 'arraybuffer' as 'json'});
  }

  public getAllHistory(): Observable<UserHistoryEntry[]> {
    this.setTokenAndAccessRoleUid();
    return this.http.get<UserHistoryEntry[]>(this.allHistoryUrl, {headers: this.headers});
  }

  public getHistoryByUserId(userId: string): Observable<UserHistoryEntry[]> {
    this.setTokenAndAccessRoleUid();
    return this.http.get<UserHistoryEntry[]>(this.allHistoryUrl + '/' + userId, {headers: this.headers});
  }

  public getHistoryByAction(action: string): Observable<UserHistoryEntry[]> {
    this.setTokenAndAccessRoleUid();
    return this.http.get<UserHistoryEntry[]>(this.allHistoryUrl + '/action/' + action, {headers: this.headers});
  }

  public getHistoryByAppId(appId: string): Observable<UserHistoryEntry[]> {
    this.setTokenAndAccessRoleUid();
    return this.http.get<UserHistoryEntry[]>(this.allHistoryUrl + '/app/' + appId, {headers: this.headers});
  }

  private setTokenAndAccessRoleUid(): void {
    this.token = this.identityService.getToken();
    this.userUID = this.authService.membership.uid;
    this.accessRoleUid = this.authService.accessRoles[0].uid;
    this.headers = new HttpHeaders()
      .set('authorization', 'Bearer ' + this.token)
      .set('accessRoleUid', this.accessRoleUid);
  }

}
