<div class="container-fluid">
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a class="nav-link" routerLink="/apps">All Applications</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/version">Compatibility Lookup</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/history">User History</a>
    </li>
  </ul>
  <div class="row" style="padding-top: 10px"></div>
  <div class="fade-in">
    <h5>
      Edit Application
    </h5>
    <div class="row" style="padding-top: 10px"></div>
  <div class="fade-in">
    <table class="table table-hover">
      <tr>
        <th scope="col">Name</th>
        <th scope="col">App Version</th>
        <th scope="col">N4 Compatibility</th>
        <th scope="col" style="width: 50%">Release Notes</th>
      </tr>
      <tbody>
      <tr>
        <td>
          {{ app._appName }}
        </td>
        <td>{{ app._appVersion }}</td>
        <td>
          <div *ngFor="let n4Version of app._n4Compatibility">
            {{ n4Version }}
          </div>
        </td>
        <td style="width: 50%;">
          {{ app._releaseNotes }}
        </td>
      </tr>
      </tbody>
    </table>
    <div *ngIf="!editOptionSelected">
      <p>
        Choose the attribute you would like to edit for this application.
      </p>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" (click)="editAppName=true; editOptionSelected=true;">
        <label class="form-check-label" >Application Name</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
               value="option2" (click)="editAppVersion=true; editOptionSelected=true;">
        <label class="form-check-label" for="inlineRadio3" >Application Version</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio5"
               value="option5" (click)="editReleaseNotes=true; editOptionSelected=true;">
        <label class="form-check-label" for="inlineRadio5" >Release Notes</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3"
               value="option3" (click)="addAppCompatibility=true; editOptionSelected=true;">
        <label class="form-check-label" for="inlineRadio3">Add N4 Compatibility</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4"
               value="option4" (click)="removeAppCompatibility=true; editOptionSelected=true;">
        <label class="form-check-label" for="inlineRadio4" > Remove N4 Compatibility</label>
      </div>
    </div>

    <div *ngIf="editAppName" class="fade-in">
      <div class="row" style="padding-top: 10px"></div>
      <p>
        Enter the updated application name.
      </p>
      <label>
        <input type="text" (change)="setUpdatedAppName($event)">
      </label>
      <div>
        <button type="submit" (click)="updateAppName()" class="btn btn-outline-dark" style="margin-right: 5px;">
          Submit
        </button>
        <button type="submit" (click)="editAppName=false; editOptionSelected=false;" class="btn btn-outline-dark">
          Back
        </button>
      </div>
    </div>

    <div *ngIf="editAppVersion" class="fade-in">
      <div class="row" style="padding-top: 10px"></div>
      <p>
        Enter the updated application version.
      </p>
      <label>
        <input type="text" (change)="setUpdatedAppVersion($event)">
      </label>
      <div>
        <button type="submit" (click)="updateAppVersion()" class="btn btn-outline-dark" style="margin-right: 5px;">
          Submit
        </button>
        <button type="submit" (click)="editAppVersion=false; editOptionSelected=false;" class="btn btn-outline-dark">
          Back
        </button>
      </div>
    </div>

    <div *ngIf="addAppCompatibility" class="fade-in">
      <div class="row" style="padding-top: 10px"></div>
      <p>
        Enter the compatible N4 version to be added.
      </p>
      <label>
        <input type="text" (change)="setNewN4Compatibility($event)">
      </label>
      <div>
        <button type="submit" (click)="addN4Compatibility()" class="btn btn-outline-dark" style="margin-right: 5px;">
          Submit
        </button>
        <button type="submit" (click)="addAppCompatibility=false; editOptionSelected=false;" class="btn btn-outline-dark">
          Back
        </button>
      </div>
    </div>

    <div *ngIf="removeAppCompatibility" class="fade-in">
      <div class="row" style="padding-top: 10px"></div>
      <p>
        Choose the N4 version to be removed from this application's compatibility list.
      </p>
      <div *ngFor="let compatibility of app._n4Compatibility" style="padding: 5px; margin-left: 10px;">
        <input class="form-check-input" type="radio" name="inlineRadioOptions" value="{{compatibility}}"
               (click)="deleteN4Compatibility(compatibility)">
        <label class="form-check-label">{{compatibility}}</label>
      </div>
    </div>

    <div *ngIf="editReleaseNotes" class="fade-in">
      <div class="row" style="padding-top: 10px"></div>
      <p>
        Enter the new release notes below.
      </p>
      <label>
        <textarea type="text" (change)="setNewReleaseNotes($event)" rows="4" cols="50"></textarea>
<!--        <input type="text" (change)="setNewReleaseNotes($event)">-->
      </label>
      <div>
        <button type="submit" (click)="updateReleaseNotes()" class="btn btn-outline-dark" style="margin-right: 5px;">
          Submit
        </button>
        <button type="submit" (click)="editReleaseNotes=false; editOptionSelected=false;" class="btn btn-outline-dark">
          Back
        </button>
      </div>
    </div>

    <div *ngIf="appUpdated" class="fade-in">
      <p>
        Your updates have been successfully applied.
      </p>
      <button type="button" style="margin: 10px" data-bs-toggle="button" class="btn btn-outline-dark" (click)="goHome()">
        Home
      </button>
    </div>

</div>
  </div>
</div>
