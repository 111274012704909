import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NcsRootLayoutModule } from '@navis/design';
import {
  NcsCoreModule,
  ErrorAdapter,
  AdminAuthService,
  AdminConfigService,
  AuthenticationService,
  NavisCoreService
} from '@navis/core';
import { coreConfig } from './app.configuration';
import { ErrorAdapterService } from '../shared/core/errorAdapter.service';
import { NcsSnackbarService, NcsSnackbarModule } from '@navis/components';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TestModule } from './test/test.module';
import { AppListComponent } from './common-components/app-list/app-list.component';
import {ApplicationService} from './application.service';
import { ViewAppInfoComponent } from './admin-components/view-app-info/view-app-info.component';
import { VersionQueryComponent } from './common-components/version-query/version-query.component';
import {ReactiveFormsModule} from '@angular/forms';
import { AdditionalAppsComponent } from './customer-components/additional-apps/additional-apps.component';
import { ManageAppsComponent } from './admin-components/manage-apps/manage-apps.component';
import { HistoryComponent } from './admin-components/history/all-history/history.component';
import { UserHistoryComponent } from './admin-components/history/user-history/user-history.component';
import { AddAppComponent } from './admin-components/add-app/add-app.component';
import { DeleteAppComponent } from './admin-components/delete-app/delete-app.component';
import { EditAppComponent } from './admin-components/edit-app/edit-app.component';
import { DownloadComponent } from './common-components/download/download.component';
import {CoreLayoutModule} from './core-layout/core-layout.module';
import { JwPaginationModule } from 'jw-angular-pagination';
import {MatRadioModule} from '@angular/material/radio';
import { AppHistoryComponent } from './admin-components/history/app-history/app-history.component';
import { ActionHistoryComponent } from './admin-components/history/action-history/action-history.component';

// @ts-ignore
// @ts-ignore
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    TestModule,
    NcsSnackbarModule.forRoot(),
    NcsRootLayoutModule,
    NcsCoreModule.forRoot(coreConfig),
    ReactiveFormsModule,
    CoreLayoutModule,
    JwPaginationModule,
    MatRadioModule,
  ],
  declarations: [
    AppComponent,
    AppListComponent,
    ViewAppInfoComponent,
    VersionQueryComponent,
    AdditionalAppsComponent,
    ManageAppsComponent,
    HistoryComponent,
    UserHistoryComponent,
    AddAppComponent,
    DeleteAppComponent,
    EditAppComponent,
    DownloadComponent,
    AppHistoryComponent,
    ActionHistoryComponent
  ],
  providers: [
    NavisCoreService,
    AuthenticationService,
    ApplicationService,
    NcsSnackbarService,
    {
      provide: ErrorAdapter,
      useClass: ErrorAdapterService
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
