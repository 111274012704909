import {Component, Input, OnInit} from '@angular/core';
import {Application} from '../../model/application/application';
import {AuthenticationService, HttpErrorHandlerService} from '@navis/core';
import {ApplicationService} from '../../application.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from "rxjs";
import {AppListComponent} from "../../common-components/app-list/app-list.component";

@Component({
  selector: 'app-edit-app',
  templateUrl: './edit-app.component.html',
  styleUrls: ['./edit-app.component.scss']
})
export class EditAppComponent implements OnInit {

  appSelected: boolean;
  showEditOptions: boolean = false;
  app: Application = new Application();
  allApps: Application[];
  appUpdated: boolean = false;
  appName: string;
  appVersion: string;
  updatedAppName: string = '';
  updatedAppVersion: string = '';
  newN4Compatibility: string = '';
  updatedReleaseNotes: string = '';
  editOptionSelected: boolean = false;
  editAppName: boolean = false;
  editAppVersion: boolean = false;
  addAppCompatibility: boolean = false;
  removeAppCompatibility: boolean = false;
  editReleaseNotes: boolean = false;
  // @Input('app') app: Application;
  private routeSub: Subscription;
  appId: string;

  constructor(
    private appService: ApplicationService,
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private errorHandlerService: HttpErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(params => {
      this.appId = params.appid;
    });
    this.appService.findById(this.appId).subscribe(data => {
        this.app = data;
      }, error => {
        if (error.status === 404) {
          window.alert('Application not found.');
        } else {
          this.errorHandlerService.handleError(error);
        }
        this.router.navigate(['/apps']);
      });
    // this.appService.findById(this.appId).subscribe()
    // window.alert(this.appId);
    // this.appSelected = false;
    // this.appService.findAllAdminApps().subscribe(data => {
    //   this.allApps = data;
    //   if (this.allApps.length === 0) {
    //     window.alert('No applications found.');
    //     this.router.navigate(['/apps']);
    //   }
    // }, error => {
    //   this.errorHandlerService.handleError(error);
    // });
    // this.appService.findById(this.appId).subscribe(data => {
    //   this.app = data;
    // }, error => {
    //   if (error.status === 404) {
    //     window.alert('Application not found.');
    //   } else {
    //     this.errorHandlerService.handleError(error);
    //   }
    //   this.router.navigate(['/apps']);
    // });
  }

  // onAppSelection(selectedAppId: string): void {
  //   this.appSelected = true;
  //   this.appService.findById(selectedAppId).subscribe(data => {
  //     this.app = data;
  //   }, error => {
  //     if (error.status === 404) {
  //       window.alert('Application not found.');
  //     } else {
  //       this.errorHandlerService.handleError(error);
  //     }
  //     this.router.navigate(['/apps']);
  //   });
  // }

  onSubmit(): void {
    this.appSelected = true;
    this.appService.findApp(this.appName, this.appVersion).subscribe(data => {
      this.app = data;
      let result: boolean = window.confirm('Are you sure you want to edit ' + this.app._appName + ' version ' +
        this.app._appVersion + '?');
      if (result) {
        this.showEditOptions = true;
      } else {
        this.router.navigate(['/manage']);
      }
    },
      error => {
      if (error.status === 404) {
        window.alert('Could not find application matching your query.');
        this.appSelected = false;
        this.router.navigate(['/manage/edit']);
      } else {
        this.errorHandlerService.handleError(error);
      }
      });
  }

  inputAppName(e): void {
    this.appName = e.target.value;
  }

  inputAppVersion(e): void {
    this.appVersion = e.target.value;
  }

  setUpdatedAppName(e): void {
    let regex = /^\w+( \w+)*$/;
    if (!e.target.value.match(regex)) {
      window.alert('Forbidden character in application name, only alphabetical characters allowed.');
    } else {
      this.updatedAppName = e.target.value;
    }
  }

  setUpdatedAppVersion(e): void {
    let regex = /^(\d+\.)+\d+$/;
    if (!e.target.value.match(regex)) {
      window.alert('Forbidden character in application version, only numbers and periods allowed.');
    } else {
      this.updatedAppVersion = e.target.value;
    }
  }

  setNewN4Compatibility(e): void {
    let regex = /^(\d+\.)+\d+$/;
    if (!e.target.value.match(regex)) {
      window.alert('Forbidden character in application version, only numbers and periods allowed.');
    } else {
      this.newN4Compatibility = e.target.value;
    }
  }

  setNewReleaseNotes(e): void {
    this.updatedReleaseNotes = e.target.value;
  }

  updateAppName(): void {
      this.appService.editAppName(this.app._id, this.updatedAppName).subscribe(data => {
        this.app = data;
        this.appUpdated = true;
        this.editAppName = false;
      }, error => {
        if (error.status === 404) {
          window.alert('Application not found.');
          this.router.navigate(['/apps']);
        } else {
          this.errorHandlerService.handleError(error);
        }
      });
  }

  updateAppVersion(): void {
    this.appService.editAppVersion(this.app._id, this.updatedAppVersion).subscribe(data => {
      this.app = data;
      this.appUpdated = true;
      this.editAppVersion = false;
      },
        error => {
          if (error.status === 404) {
            window.alert('Application not found.');
          } else {
            this.errorHandlerService.handleError(error);
          }
          this.router.navigate(['/apps']);
        });
  }

  updateReleaseNotes(): void {
    this.appService.editReleaseNotes(this.app._id, this.updatedReleaseNotes).subscribe(data => {
      this.app = data;
      this.appUpdated = true;
      this.editReleaseNotes = false;
    }, error => {
      if (error.status === 404) {
        window.alert('Application not found.');
      } else {
        this.errorHandlerService.handleError(error);
      }
      this.router.navigate(['/apps']);
    });
  }

  addN4Compatibility(): void {
    this.appService.addN4Compatibility(this.app._id, this.newN4Compatibility).subscribe(data => {
        this.app = data;
        this.appUpdated = true;
        this.addAppCompatibility = false;
      },
      error => {
        if (error.status === 404) {
          window.alert('Application not found.');
        } else {
          this.errorHandlerService.handleError(error);
        }
        this.router.navigate(['/apps']);
      });
  }

  deleteN4Compatibility(removeCompatibility: string): void {
    let result: boolean = window.confirm('Are you sure you want to delete ' + removeCompatibility + ' from the compatibility list?');
    if (result) {
      this.appService.deleteN4Compatibility(this.app._id, removeCompatibility).subscribe(data => {
          this.app = data;
          this.appUpdated = true;
          this.removeAppCompatibility = false;
        },
        error => {
          if (error.status === 404) {
            window.alert('Application not found.');
          } else {
            this.errorHandlerService.handleError(error);
          }
          this.router.navigate(['/apps']);
        });
    }
  }

  goHome() {
    AppListComponent.showActions = false;
    this.router.navigate(['/apps']);
  }

}
