import { Injectable } from '@angular/core';
import { ErrorAdapter, IdentityService } from '@navis/core';
import { NcsSnackbarService, SnackbarState } from '@navis/components';

@Injectable({
  providedIn: 'root'
})
export class ErrorAdapterService extends ErrorAdapter {

  constructor(private _identity: IdentityService, private _snackbar: NcsSnackbarService) {
    super();
  }

  public forbiddenException(): void {
    this._snackbar.show({
      message: 'Sorry - you are not allowed to execute this action.',
      state: SnackbarState.Info
    });
  }

  public serverTimeoutException(): void {
    this._snackbar.show({
      message: 'The connection took to long. Please retry.',
      state: SnackbarState.Error
    });
  }

  public sessionTimedOut(): void {
    this._snackbar.show({
      message: 'Your session timed out. Logging in.',
      state: SnackbarState.Error
    });

    const path = window.location.pathname + window.location.search;
    localStorage.setItem('path', path);

    this._identity.auth();
  }

  public unhandledServerException(): void {
    this._snackbar.show({
      message: 'An unhandled server exception occurred.',
      state: SnackbarState.Error
    });
  }
}
