import { Component, OnInit } from '@angular/core';
import {Application} from '../../model/application/application';
import {ApplicationService} from '../../application.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpErrorHandlerService} from '@navis/core';

@Component({
  selector: 'app-view-app-info',
  templateUrl: './view-app-info.component.html',
  styleUrls: ['./view-app-info.component.scss']
})
export class ViewAppInfoComponent implements OnInit {

  app: Application;
  private routeSub: Subscription;
  id: string;

  constructor(
    private appService: ApplicationService,
    private route: ActivatedRoute,
    private router: Router,
    private errorHandler: HttpErrorHandlerService,
  ) { }

  ngOnInit() {
    this.routeSub = this.route.params.subscribe(params => {
      this.id = params.id;
    });
    this.appService.findById(this.id).subscribe(data => {
      if (data === null) {
        window.alert('App with Id ' + this.id + ' not found.');
        this.router.navigate(['/apps']);
      }
      this.app = data;
    }, error => {
      if (error.status === 404) {
        window.alert('Application not found.');
        this.router.navigate(['/apps']);
      } else {
        this.errorHandler.handleError(error);
      }
    });
  }

  get appInfo() {
    return this.app;
  }

  delete() {
    let result: boolean = window.confirm('Are you sure you want to delete ' + this.app._appName + ' version ' +
      this.app._appVersion + '?');
    if (result) {
      this.appService.deleteApp(this.id).subscribe(data => {}, error => {
        if (error.status === 404) {
          window.alert('Application not found.');
          this.router.navigate(['/apps']);
        } else {
          this.errorHandler.handleError(error);
        }
      });
      this.router.navigate(['/apps']);
    } else {
      this.router.navigate(['/apps/' + this.id]);
    }
  }
}
