<div class="container-fluid">
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a class="nav-link" routerLink="/apps">My Applications</a>
    </li>
    <li class="nav-item">
      <a class="nav-link active" routerLink="/moreapps" style="border-bottom: 2px solid #7AC142;">Other Applications</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/version">Compatibility Lookup</a>
    </li>
  </ul>
  <div class="row" style="padding-top: 10px"></div>
    <table class="table table-borderless table-hover fade-in">
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Min N4 Version</th>
        <th scope="col">Description</th>
        <th scope="col">Learn More</th>
      </tr>
      <tbody>
      <tr *ngIf="!authService.featureAvailable('hatch')">
        <td>
          <svg
            class="icon-styles"
            viewBox="0 0 50 50"
            width="50"
            height="50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M39.5 14.5A1.5 1.5 0 0038 13H9.5A1.5 1.5 0 008 14.5v20A1.5 1.5 0 009.5 36H38a1.5 1.5 0 001.5-1.5v-20zM10 14h27v21H10V14zm14.809 2.412A.999.999 0 0023 17v3h-6a1 1 0 100 2h6v8h-2a1 1 0 100 2h6a1 1 0 100-2h-2v-8h6a1 1 0 001-1v-.073a1.5 1.5 0 00-.83-1.342l-6.276-3.138a.65.65 0 00-.085-.035zM20 26v-2h-5v2h5zm5-6v-1.5l3.001 1.5H25z" fill="white"/>
          </svg>
          Hatch
        </td>
        <td>1.0.0</td>
        <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
        <td><a>Link</a></td>
      </tr>
      <tr *ngIf="!authService.featureAvailable('control-room-ui')">
        <td>
          <svg
               class="icon-styles"
               viewBox="0 0 50 50"
               width="50"
               height="50"
               fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M30 14h5a1 1 0 011 1v6a1 1 0 01-1 1h-5a1 1 0 01-1-1v-6a1 1 0 011-1zm0 7h5v-6h-5v6zm0 2h5a1 1 0 011 1v4a1 1 0 01-1 1h-5a1 1 0 01-1-1v-4a1 1 0 011-1zm0 5h5v-4h-5v4zM19 14.333A4.672 4.672 0 0014.333 19c0 3.5 4.667 8.667 4.667 8.667S23.667 22.5 23.667 19A4.672 4.672 0 0019 14.333zm0 1.334c1.833 0 3.333 1.5 3.333 3.333 0 1.85-1.5 3.333-3.333 3.333A3.332 3.332 0 0115.667 19c0-1.833 1.5-3.333 3.333-3.333z" fill="white"/>
            <path d="M37.09 10H10.91C9.31 10 8 11.305 8 12.9v17.4c0 1.595 1.31 2.9 2.91 2.9h10.18v2.9h-1.908a1 1 0 00-1 1v.9h11.636v-.9a1 1 0 00-1-1H26.91v-2.9h10.182c1.6 0 2.909-1.305 2.909-2.9V12.9c0-1.595-1.31-2.9-2.91-2.9zm0 20.3H10.91V12.9h26.18v17.4z" fill="white"/>
          </svg>
          Control Room UI
        </td>
        <td>1.2.0</td>
        <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
        <td><a>Link</a></td>
      </tr>
      <tr *ngIf="!authService.featureAvailable('nvmt')">
        <td>
          <svg
               class="icon-styles"
               viewBox="0 0 50 50"
               width="50"
               height="50"
               fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M43.435 8c1.116 0 2.021.905 2.021 2.022v26.956A2.022 2.022 0 0143.435 39H5.022A2.022 2.022 0 013 36.978V10.022C3 8.905 3.905 8 5.022 8h38.413zM42 10H6v26.978h36.087L42 10z" fill="white"/>
            <path d="M13.188 31.188c.655 0 1.187.531 1.187 1.187v1.188a1.188 1.188 0 01-2.375 0v-1.188c0-.656.532-1.188 1.188-1.188zM23.28 11a2.969 2.969 0 012.91 3.565l3.622-.002c.655 0 1.187.531 1.187 1.187v13.063a1.188 1.188 0 01-2.375 0V16.937h-14.25v11.875a1.188 1.188 0 01-2.375 0V15.75c0-.656.532-1.188 1.188-1.188l3.622.003A2.969 2.969 0 0119.719 11h3.562zm-.593 7.125a3.563 3.563 0 013.562 3.563v2.375a3.563 3.563 0 01-3.563 3.562h-2.375a3.563 3.563 0 01-3.562-3.563v-2.375a3.563 3.563 0 013.563-3.562h2.375zm.593 1.357h-3.56c-.914 0-1.667.688-1.77 1.574l-.012.207v3.224c0 .913.688 1.666 1.574 1.769l.208.012h3.562c.914 0 1.667-.688 1.77-1.574l.012-.207v-3.224c0-.983-.798-1.78-1.782-1.78zm0-7.294h-3.56a1.781 1.781 0 00-1.68 2.376h6.921a1.781 1.781 0 00-1.68-2.377z" fill="white"/>
            <path d="M34.667 26.417v.666h2l2 2.667v3.333H38a2 2 0 11-4 0h-2a2 2 0 11-4 0 2 2 0 11-4 0V31.75h.51a1.995 1.995 0 011.49-.667c.592 0 1.124.258 1.49.667h1.02a1.995 1.995 0 011.49-.667c.592 0 1.124.258 1.49.667h1.843v-5.333h1.334zM26 32.083a1 1 0 100 2 1 1 0 100-2zm10 0a1 1 0 100 2 1 1 0 100-2zm-6 0a1 1 0 100 2 1 1 0 100-2zm6.333-4h-1.666v1.667h2.973l-1.307-1.667z" fill="white"/>
          </svg>
          NVMT
        </td>
        <td>1.3.2</td>
        <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
        <td><a>Link</a></td>
      </tr>
      <tr *ngIf="!authService.featureAvailable('reefer')">
        <td>
          <svg
            class="icon-styles"
            viewBox="0 0 50 50"
            width="50"
            height="50"
            fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.259 10.467c-1.383 0-2.509 1.086-2.509 2.42v15.307l-.52.37c-1.331.945-2.095 2.396-2.095 3.98 0 2.728 2.299 4.947 5.124 4.947 2.825 0 5.123-2.22 5.123-4.947 0-1.584-.764-3.035-2.095-3.98l-.52-.37V12.888c0-1.335-1.125-2.421-2.508-2.421zm0 29.49c-4.235 0-7.68-3.325-7.68-7.413 0-2.127.967-4.162 2.616-5.563V12.888c0-2.695 2.271-4.888 5.064-4.888 2.792 0 5.063 2.193 5.063 4.888V26.98c1.649 1.4 2.616 3.436 2.616 5.563 0 4.088-3.445 7.414-7.68 7.414z" fill="white"/>
            <path d="M15.386 16.552h-2.099a1.287 1.287 0 000 2.574h2.098a1.287 1.287 0 100-2.574zm0 8.092h-2.099a1.287 1.287 0 100 2.574h2.098a1.287 1.287 0 000-2.574zm0-4.046h-.043a1.287 1.287 0 000 2.574h.043a1.287 1.287 0 100-2.574zm0-8.092h-.043a1.287 1.287 0 100 2.575h.043a1.287 1.287 0 000-2.575z" fill="white"/>
          </svg>
          Reefer
        </td>
        <td>1.3.2</td>
        <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
        <td><a>Link</a></td>
      </tr>
      <tr *ngIf="!authService.featureAvailable('lashing')">
        <td>
          <svg
            class="icon-styles"
            viewBox="0 0 50 50"
            width="50"
            height="50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M28 10h-8v7.5a.5.5 0 01.972-.165l.233.665h5.59l.233-.665a.5.5 0 01.972.149V10zm0 7.517a.5.5 0 01-.028.148l-.117.335H28v-.483zM26.095 20h-4.19L24 25.987 26.095 20zm-5.95-2l-.117-.335a.497.497 0 01-.028-.164V18h.145zm.7 2l2.625 7.5-.175.5H20v-8h.845zm3.86 8l-.175-.5 2.625-7.5H28v8h-3.295zm-1.05 2h.69l2.683 7.665a.5.5 0 00.456.335h-6.967a.5.5 0 00.455-.335L23.655 30zm-1.06 0H20v7.5c0-.055.01-.11.028-.165L22.595 30zM20 37.5v.5h.484a.499.499 0 01-.484-.5zm7.517.5H28v-.483a.5.5 0 01-.483.483zm.483-.516a.499.499 0 00-.028-.149L25.405 30H28v7.484zM18 20h-8v8h.463l-.435-.651a.155.155 0 01-.028-.087c0-.108.129-.21.335-.247.26-.048.546.024.637.16l.551.825h4.954l.551-.825c.091-.136.377-.208.637-.16.2.037.328.133.335.238V20zm0 7.27a.157.157 0 01-.028.079l-.435.651H18v-.73zM15.14 30h-2.28L14 31.707 15.14 30zm-3.34 0H10v8h.477a.913.913 0 01-.142-.014c-.206-.038-.335-.14-.335-.248 0-.028.01-.058.028-.086L13.47 32.5 11.8 30zm-1.277 8h6.954c-.197-.005-.38-.071-.449-.175L14 33.293l-3.028 4.532c-.07.104-.251.17-.45.175zm7 0H18v-.253c-.007.105-.134.202-.335.239a.913.913 0 01-.142.014zm.477-.27a.157.157 0 00-.028-.078L14.53 32.5 16.2 30H18v7.73zM38 20h-8v8h.463l-.435-.651c-.091-.137.046-.286.307-.334.26-.048.546.024.637.16l.551.825h4.954l.551-.825c.091-.136.377-.208.637-.16.2.037.328.133.335.238V20zm0 7.27a.157.157 0 01-.028.079l-.435.651H38v-.73zM35.14 30h-2.28L34 31.707 35.14 30zm-3.34 0H30v8h.477a.913.913 0 01-.142-.014c-.26-.048-.398-.198-.307-.334L33.47 32.5 31.8 30zm-1.277 8h6.954c-.197-.005-.38-.071-.449-.175L34 33.293l-3.028 4.532c-.07.104-.251.17-.45.175zm7 0H38v-.253c-.007.105-.134.202-.335.239a.913.913 0 01-.142.014zm.477-.27a.157.157 0 00-.028-.078L34.53 32.5 36.2 30H38v7.73z" fill="white"/>
          </svg>
          Lashing
        </td>
        <td>1.3.2</td>
        <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
        <td><a>Link</a></td>
      </tr>
      <tr *ngIf="!authService.featureAvailable('pinning')">
        <td>
          <svg
            class="icon-styles"
            viewBox="0 0 50 50"
            width="50"
            height="50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"><path
            d="M35 23H13v1h22v-1zm-7-9h-8v8h8v-8zm0 11h-8v8h8v-8z" fill="white"/>
          </svg>
          Pinning
        </td>
        <td>1.3.2</td>
        <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
        <td><a>Link</a></td>
      </tr>
      </tbody>
    </table>
</div>
