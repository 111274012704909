import {Component, OnInit} from '@angular/core';
import {AuthenticationService, HttpErrorHandlerService} from '@navis/core';
import {ApplicationService} from '../../application.service';
import {Router} from '@angular/router';


@Component({
  selector: 'app-add-app',
  templateUrl: './add-app.component.html',
  styleUrls: ['./add-app.component.scss']
})
export class AddAppComponent implements OnInit {

  formData: FormData = new FormData();
  errorMsg: string;
  showSpinner: boolean = false;

  constructor(
    private appService: ApplicationService,
    private authService: AuthenticationService,
    private router: Router,
    private errorHandlerService: HttpErrorHandlerService,
  ) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.showSpinner = true;
    this.appService.addApp(this.formData).subscribe(data => {
      this.disableLoadingScreen();
      window.alert('App added successfully.');
      this.router.navigate(['/apps']);
      },
      error => {
      this.disableLoadingScreen();
      if (error.status === 422) {
        window.alert('Unable to add file, formatting does not match expected. Please refer to https://confluence.navis.com/pages/viewpage.action?pageId=191431464 for correct formatting');
      } else if (error.status === 409) {
        window.alert('Failure: Attempting to upload duplicate application.');
      } else if (error.status === 424) {
        window.alert('Failure: Could not upload file to AWS.');
      } else {
        this.errorHandlerService.handleError(error);
      }
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
      });
      }
    );
  }

  inputFile(e): void {
    let fileList: FileList = e.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      this.formData.set('uploadFile', file);
      this.formData.set('fileName', file.name);
    }
  }

  private disableLoadingScreen() {
    const spinner = document.getElementById('navis-spinner');

    if (spinner) {
      // this.canShowApp = shouldSeeApp;
      setTimeout(() => {
        spinner.classList.add('disabled');

        setTimeout(() => {
          spinner.remove();
        }, 1000);
      }, 500);
    }
  }
}
