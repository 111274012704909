<div class="container-fluid">
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a class="nav-link" routerLink="/apps">All Applications</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/version">Compatibility Lookup</a>
    </li>
    <li class="nav-item">
      <a class="nav-link active" (click)="refresh()" style="border-bottom: 2px solid #7AC142;">User History</a>
    </li>
  </ul>
  <div class="row" style="padding-top: 10px"></div>
  <div class="card text-center m-3">
    <div class="card-header">
      <h4>
        History for Action: {{action}}
      </h4>
      </div>
    <div class="card-body">
      <table class="table table-hover table-borderless fade-in">
        <tr>
          <th scope="col">User Email</th>
          <th scope="col">Application Name</th>
          <th scope="col">Application Version</th>
          <th scope="col">Date/Time</th>
        </tr>
        <tbody>
        <tr *ngFor="let item of pageOfItems">
          <td>
            <a routerLink="/history/{{ item._userId }}">{{ item._userEmail }}</a>
          </td>
          <td>
            {{ item._appName }}
          </td>
          <td>{{ item._appVersion }}</td>
          <td>{{ item._dateTime }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="card-footer pb-0 pt-3">
      <jw-pagination [items]="items" (changePage)="onChangePage($event)"></jw-pagination>
    </div>
  </div>
</div>
