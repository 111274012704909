import { Component, OnInit } from '@angular/core';
import {Application} from '../../model/application/application';
import {AuthenticationService, HttpErrorHandlerService} from '@navis/core';
import {ApplicationService} from '../../application.service';
import {Router} from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'app-delete-app',
  templateUrl: './delete-app.component.html',
  styleUrls: ['./delete-app.component.scss']
})
export class DeleteAppComponent implements OnInit {

  app: Application = new Application();
  appName: string;
  appVersion: string;
  versionPattern: RegExp = /^(\d+\.)+\d+$/;
  appNamePattern: RegExp = /^\w+( \w+)*$/;
  deleteAppForm = this.formBuilder.group({
    appName: ['', [Validators.required, Validators.pattern(this.appNamePattern)]],
    appVersion: ['', [Validators.required, Validators.pattern(this.versionPattern)]]
    });

  constructor(
    private appService: ApplicationService,
    private authService: AuthenticationService,
    private router: Router,
    private errorHandlerService: HttpErrorHandlerService,
    private formBuilder: FormBuilder,
    ) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    if (this.deleteAppForm.invalid) {
      window.alert('Invalid search.');
      this.deleteAppForm.reset();
      this.router.navigate(['/manage/delete']);
      return;
    }
    this.appName = this.deleteAppForm.get('appName').value;
    this.appVersion = this.deleteAppForm.get('appVersion').value;
    this.appService.findApp(this.appName, this.appVersion).subscribe(data => {
      this.app = data;
      let result: boolean = window.confirm('Are you sure you want to delete ' + this.app._appName + ' version ' +
        this.app._appVersion + '?');
      if (!result) {
        this.router.navigate(['/manage/delete']);
      } else {
        this.appService.deleteApp(this.app._id).subscribe(data => {}, error => {
          if (error.status === 404) {
            window.alert('Application not found.');
            this.router.navigate(['/apps']);
          } else {
            this.errorHandlerService.handleError(error);
          }
        });
        this.router.navigate(['/apps']);
      }
    },
      error => {
      if (error.status === 404) {
        window.alert('Could not find application matching your query.');
        this.deleteAppForm.reset();
        this.router.navigate(['/manage/delete']);
      } else {
        this.errorHandlerService.handleError(error);
      }
      });
  }
}
