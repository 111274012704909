import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '@navis/core';
import { TestComponent } from './test.component';

const routes: Routes = [
  {
    path: 'test', // path has to match with route in app.configuration
    component: TestComponent,
    canActivate: [AuthGuardService],
    data: [
      {
        title: 'Testing 123',
        feature: 'downloads' // feature which is provided by navis-company-admin
      }
    ]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TestRoutingModule {
}
