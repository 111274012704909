import { Component, OnInit } from '@angular/core';
import {App, AuthenticationService} from '@navis/core';

@Component({
  selector: 'app-additional-apps',
  templateUrl: './additional-apps.component.html',
  styleUrls: ['./additional-apps.component.scss']
})
export class AdditionalAppsComponent implements OnInit {

  isAdmin: boolean = false;
  authApps: App[];

  constructor(public authService: AuthenticationService) { }

  ngOnInit(): void {
    this.authApps = this.authService.userApps.getValue();
    if (this.authApps.length > 1) {
      this.isAdmin = true;
    }
  }

}
