import { Component, OnInit } from '@angular/core';
import {ApplicationService} from '../../../application.service';
import {HttpErrorHandlerService} from '@navis/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {UserHistoryEntry} from '../../../model/user-history-entry/user-history-entry';

@Component({
  selector: 'app-user-history',
  templateUrl: './user-history.component.html',
  styleUrls: ['./user-history.component.scss']
})
export class UserHistoryComponent implements OnInit {

  id: string;
  private routeSub: Subscription;
  entries: UserHistoryEntry[];

  constructor(
    private appService: ApplicationService,
    private router: Router,
    private errorHandlerService: HttpErrorHandlerService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(params => {
      this.id = params.id;
    });
    this.appService.getHistoryByUserId(this.id).subscribe(data => {
      this.entries = data;
    }, error => {
      if (error.status === 404) {
        window.alert('History not found for this user');
      } else {
        this.errorHandlerService.handleError(error);
      }
      this.router.navigate(['/apps']);
    });
  }

}
