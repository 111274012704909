<div class="container-fluid">
    <div *ngIf="authApps.length == 1; else navElse">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link" routerLink="/apps">My Applications</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/moreapps">Other Applications</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" routerLink="/version" (click)="submitForm = false" style="border-bottom: 2px solid #7AC142;">Compatibility Lookup</a>
        </li>
      </ul>
    </div>
    <ng-template #navElse>
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link" routerLink="/apps">All Applications</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" routerLink="/version" (click)="submitForm = false" style="border-bottom: 2px solid #7AC142;">Compatibility Lookup</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/history">User History</a>
        </li>
      </ul>
    </ng-template>
  </div>
  <div class="container-fluid fade-in">
    <div  class="row" style="color: darkslategrey">
      <form [formGroup]="versionQueryForm" (ngSubmit)="onSubmit()" class="col-4" style="padding-left: 6%">
        <div style="padding-bottom: 3%;">
          Application:
          <select name="inputN4" formControlName="appName">
            <option>Choose Application</option>
            <option *ngIf="authService.featureAvailable('nvmt')">NVMT</option>
            <option *ngIf="authService.featureAvailable('crui')">Control Room UI</option>
          </select>
        </div>
        <div style="padding-bottom: 3%;">
          <label>
            N4 Version:
            <input type="text" formControlName="n4Version">
          </label>
        </div>
        <button type="submit" name="submitButton" value="submitButton"  class="btn btn-outline-dark">Find Version</button>
      </form>
      <div *ngIf="submitForm" class="fade-in col-sm-8">
        <p style="font-size: 1rem; padding-left: 10px; color: darkslategrey; font-weight: bold;">Search Results</p>
        <table class="table table-hover">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">App Version</th>
            <th scope="col">N4 Version</th>
            <th></th>
          </tr>
          <tbody>
          <tr>
            <td>
              {{ result._appName }}
            </td>
            <td>{{ result._appVersion }}</td>
            <td>
              <div *ngFor="let n4Version of result._n4Compatibility">
                {{ n4Version }}+
              </div>
            </td>
            <td>
              <button (click)="download()" class="btn btn-success btn-sm">Download</button>
            </td>
          </tr>
          </tbody>
        </table>
        <div>
          <h4>Release Notes</h4>
          <p>
            {{ result._releaseNotes }}
          </p>
        </div>
      </div>
    </div>
  </div>

