import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import { ApplicationService } from '../../application.service';
import { CustomerVersionQuery } from '../../customer-version-query';
import {Application} from '../../model/application/application';
import {App, AuthenticationService, ErrorHandlerService, Feature} from '@navis/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-version-query',
  templateUrl: './version-query.component.html',
  styleUrls: ['./version-query.component.scss']
})
export class VersionQueryComponent implements OnInit {

  submitForm: boolean = false;
  customerQuery: CustomerVersionQuery = new CustomerVersionQuery();
  result: Application;
  versionPattern: RegExp = /^(\d+\.)+\d+$/;
  versionQueryForm = this.formBuilder.group({
    appName: '',
    n4Version: ['', [Validators.required, Validators.pattern(this.versionPattern)]]
  });
  authApps: App[];
  featureNames: string[];

  constructor(
    private appService: ApplicationService,
    private formBuilder: FormBuilder,
    public authService: AuthenticationService,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
  ) {
  }

  ngOnInit(): void {
    this.customerQuery = new CustomerVersionQuery();
    this.customerQuery._appName = 'testAppName';
    this.authApps = this.authService.userApps.getValue();
    // let features = this.authService.membership.features;
    // for (let feature of features) {
    //   this.featureNames.Add(feature.name);
    // }
  }

  onSubmit(): void {
    if (this.versionQueryForm.invalid) {
      window.alert('Invalid version');
      this.submitForm = false;
      this.versionQueryForm.reset();
      this.router.navigate(['/version']);
      return;
    }
    if (this.versionQueryForm.get('appName').value === 'Choose Application' || this.versionQueryForm.get('appName').value === '') {
      window.alert('Must select a valid application');
      this.submitForm = false;
      this.versionQueryForm.reset();
      this.router.navigate(['/version']);
      return;
    }
    this.appService.versionSearch(this.versionQueryForm.get('appName').value,
      this.versionQueryForm.get('n4Version').value).subscribe(data => {
      this.result = data;
      if (this.result === null) {
        window.alert('Could not find a match to your query');
        this.submitForm = false;
        this.router.navigate(['/version']);
      } else {
        this.submitForm = true;
      }
    }, error => {
      if (error.status === 401) {
        window.alert('User does not have valid license to this application.');
      } else {
        this.errorHandlerService.handleError(error);
      }
      this.router.navigate(['/apps']);
    });
    this.versionQueryForm.reset();
  }

  download() {
    this.appService.downloadAppById(this.result._id).subscribe((response) => {
      let file = new Blob([response], {type: 'application/zip'});
      let url = window.URL.createObjectURL(file);
      let anchor = document.createElement('a');
      anchor.download = this.result._fileName;
      anchor.href = url;
      anchor.click();
    }, error => {
      if (error.status === 401) {
        window.alert('User does not have valid license to this application.');
      } else {
        this.errorHandlerService.handleError(error);
      }
      this.router.navigate(['/apps']);
    });
  }
}
