import {Component, OnInit} from '@angular/core';
import {Application} from '../../model/application/application';
import {ApplicationService} from '../../application.service';
import {AuthenticationService, HttpErrorHandlerService} from '@navis/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-app-list',
  templateUrl: './app-list.component.html',
  styleUrls: ['./app-list.component.scss']
})
export class AppListComponent implements OnInit {

  static showActions: boolean;
  apps: Application[];
  role: string;
  customerApps: string[];
  selectedApp: Application;
  showEditDir: boolean = false;


  constructor(private appService: ApplicationService, private authService: AuthenticationService,
              private errorService: HttpErrorHandlerService, private router: Router) {
    this.role = this.authService.accessRoles[0].name;
  }

  ngOnInit() {
    if (this.role !== 'Administrator') {
      this.appService.findAllCustomerApps().subscribe(data => {
        this.customerApps = data;
      }, error => this.errorService.handleError(error));
    }
    else {
      this.appService.findAllAdminApps().subscribe(data => {
          this.apps = data;
          if (this.apps.length === 0) {
            window.alert('No apps founds.');
          } else {
            this.selectedApp = this.apps[0];
          }
        },
        error => {
          this.errorService.handleError(error);
        });
    }
  }

  onRadioSelect(element) {
    AppListComponent.showActions = true;
    this.selectedApp = element;
  }

  editButtonClick() {
    this.router.navigate(['/manage/edit/' + this.selectedApp._id]);
  }

  deleteButtonClick() {
    this.deleteApp();
  }

  addButtonClick() {
    this.router.navigate(['/manage/add']);
  }

  getShowActionsState() {
    return AppListComponent.showActions;
  }

  deleteApp(): void {
    let result: boolean = window.confirm('Are you sure you want to delete ' + this.selectedApp._appName + ' version ' +
      this.selectedApp._appVersion + '?');
    if (!result) {
      this.router.navigate(['/manage/delete']);
    } else {
      this.appService.deleteApp(this.selectedApp._id).subscribe(data => {}, error => {
        if (error.status === 404) {
          window.alert('Application not found.');
          this.router.navigate(['/apps']);
        } else {
          this.errorService.handleError(error);
        }
      });
      AppListComponent.showActions = false;
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
      });
    }
  }


}

