import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreLayoutComponent } from './core-layout.component';
import {
  NcsErrorScreenModule,
  NcsHeaderModule,
  NcsAccessRoleMenuModule,
  NcsReleaseNotesMenuModule,
  NcsAppMenuModule,
  NcsUserMenuModule
} from '@navis/design';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { NcsThemeSwitcherModule } from '@navis/components';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {RouterModule} from '@angular/router';
import {UserAccountMenuModule} from '@navis/design/lib/widgets/user-account-menu/user-account-menu.module';



@NgModule({
  imports: [
    CommonModule,
    NcsErrorScreenModule,
    NcsHeaderModule,
    NcsAccessRoleMenuModule,
    NcsReleaseNotesMenuModule,
    NcsAppMenuModule,
    NcsUserMenuModule,
    MatDividerModule,
    MatMenuModule,
    NcsThemeSwitcherModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    RouterModule,
    // UserAccountMenuModule,
  ],
  declarations: [CoreLayoutComponent],
  exports: [
    CoreLayoutComponent
  ]
})
export class CoreLayoutModule { }
