import { Component, OnInit } from '@angular/core';
import {AuthenticationService, HttpErrorHandlerService} from '@navis/core';
import {UserHistoryEntry} from '../../../model/user-history-entry/user-history-entry';
import {ApplicationService} from '../../../application.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  items: UserHistoryEntry[];
  pageOfItems: Array<any>;
  showActions: boolean = false;
  showInfo: boolean = false;

  constructor(
    private authService: AuthenticationService,
    private appService: ApplicationService,
    private router: Router,
    private errorHandlerService: HttpErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.appService.getAllHistory().subscribe(data => {
      if (data.length === 0) {
        window.alert('No history to show.');
        this.router.navigate(['/apps']);
      }
      this.items = data;
    }, error => this.errorHandlerService.handleError(error));
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    console.log(pageOfItems[0]);
    this.pageOfItems = pageOfItems;
  }

  actionSelected(e): void {
    this.router.navigate(['/history/action/' + e.target.value]);
  }
}
