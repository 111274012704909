import { environment } from '../environments/environment';
import { Auth0Config, BaseConfig, CoreConfig, DisplayConfig, LogglyConfig, SidebarModule } from '@navis/core';
import { LogLevel } from 'angular-auth-oidc-client';

export const base: BaseConfig = {
  ENV: environment.ENV,
  IdentityService: environment.Identity,
  PrivateApiEndpoint: environment.API,
  PublicCompanyCloudApi: environment.CompanyCloud,
  ServerTimeout: environment.ServerTimeout,
  AppIdentifier: 'n4mp',
  DefaultStartupPath: '/apps',
  DebugCompany: 'n4-playground',
  UsesReleaseNotesIo: false,
  NoSubdomain: true
};

export const LogglyConfiguration: LogglyConfig = {
  ENV: environment.ENV,
  App: 'N4-Marketplace-BrowserApp',
  AppComponent: 'N4-Marketplace-BrowserApp.Browser',
  LogKey: '',
  LogglyTag: 'N4-Marketplace-BrowserApp.Browser-' + environment.ENV
};

export const AuthCnf: Auth0Config = {
  identityUrl: environment.Identity,
  clientId: 'Navis.Marketplace-BrowserApp',
  scope: 'companycloud-publicapi email profile openid navis.marketplace-api',
  logLevel: environment.ENV === 'DEV' ? LogLevel.Error : LogLevel.None
};

export const LayoutOptions: DisplayConfig = {
  appNameShort: '',
  appName: ''
};

export const routes: SidebarModule[] = [
  {
    // icon: 'fas fa-tachometer-alt',
    title: 'My Marketplace',
    link: 'apps',
    feature: 'downloads',
  }
];

export const coreConfig: CoreConfig = {
  authConfig: AuthCnf,
  displayOptions: LayoutOptions,
  logglyConfig: LogglyConfiguration,
  routeConfig: routes,
  baseConfig: base
};
