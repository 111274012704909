// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ENV: 'DEV',
  API: 'http://localhost:9580/',
  // API: 'http://localhost:6868',
  Identity: 'https://account.navis.com',
  CompanyCloud: 'https://api.company.navis-cvs.com/',
  ServerTimeout: 25000,
  // // Backend Application Endpoints
  // backendApplicationBaseUrl: 'http://localhost:8080',
  // listApps: '/apps',
  // findApp: '/app',
  // addApp: '/app',
  // findAppById: '/app/',
  // versionQuery: '/version',
  // downloadById: '/download/',
  // allHistory: '/history',
  // Backend Application Endpoints -- PRODUCTION
  backendApplicationBaseUrl: 'https://software.navisn4.com:8080',
  listApps: '/apps',
  findApp: '/app',
  addApp: '/app',
  findAppById: '/app/',
  versionQuery: '/version',
  downloadById: '/download/',
  allHistory: '/history',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
