<div class="container-fluid">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link" routerLink="/apps">All Applications</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/version">Compatibility Lookup</a>
      </li>
      <li class="nav-item">
        <a class="nav-link active" routerLink="/history" style="border-bottom: 2px solid #7AC142;">User History</a>
      </li>
    </ul>
  <div class="row" style="padding-top: 10px"></div>
  <div *ngIf="showInfo" class="alert alert-info alert-dismissible fade show" role="alert">
    <p>
      &#8594; Click on a user email to view their history.
    </p>
    <p>
      &#8594; Click on an application to view the history of that application.
    </p>
    <p>
      &#8594; Use the 'Filter By Action' button to view history that is of a particular action.
    </p>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="btn-group-toggle" role="group" style="margin: 12px">
    <button style="margin-right: 20px;" type="button" class="btn btn-outline-info btn-sm fade-in" (click)="showInfo=true">
      <i class="fas fa-info-circle fa-lg"></i>
    </button>
    <button type="button" data-bs-toggle="button" class="btn btn-outline-dark fade-in" (click)="showActions=true">
      Filter By Action
    </button>
    <div *ngIf="showActions">
      <div class="row" style="padding-top: 5px"></div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio0" value="Upload" (click)="actionSelected($event)">
        <label class="form-check-label" for="inlineRadio0">Upload</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
               value="Delete" (click)="actionSelected($event)">
        <label class="form-check-label" for="inlineRadio1" >Delete</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
               value="Edit App Name" (click)="actionSelected($event)">
        <label class="form-check-label" for="inlineRadio2" >Edit Name</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3"
               value="Edit App Version" (click)="actionSelected($event)">
        <label class="form-check-label" for="inlineRadio3" >Edit Version</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4"
               value="Edit Release Notes" (click)="actionSelected($event)">
        <label class="form-check-label" for="inlineRadio4" >Edit Release Notes</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio5"
               value="Add N4 Compatibility Entry" (click)="actionSelected($event)">
        <label class="form-check-label" for="inlineRadio5">Add N4 Compatibility</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio6"
               value="Delete N4 Compatibility Entry" (click)="actionSelected($event)">
        <label class="form-check-label" for="inlineRadio6" > Remove N4 Compatibility</label>
      </div>
    </div>
  </div>
  <div class="card text-center m-3">
    <div class="card-body">
      <table class="table table-hover table-borderless fade-in">
        <tr>
          <th scope="col">User Email</th>
          <th scope="col">Action</th>
          <th scope="col">Application Name</th>
          <th scope="col">Application Version</th>
          <th scope="col">Date/Time</th>
        </tr>
        <tbody>
        <tr *ngFor="let item of pageOfItems">
          <td>
            <a routerLink="/history/{{ item._userId }}">{{ item._userEmail }}</a>
          </td>
<!--          <td>{{ item._username }}</td>-->
          <td>{{ item._action }}</td>
          <td>
            {{ item._appName }}
          </td>
<!--          <ng-template #elseBlock>-->
<!--            <td>-->
<!--              {{ item._appName }}-->
<!--            </td>-->
<!--          </ng-template>-->
          <td>{{ item._appVersion }}</td>
          <td>{{ item._dateTime }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="card-footer pb-0 pt-3">
      <jw-pagination [items]="items" (changePage)="onChangePage($event)"></jw-pagination>
    </div>
  </div>
  </div>
