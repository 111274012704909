export class Application {
  _id: string;
  _appName: string;
  _appVersion: string;
  _n4Compatibility: string[];
  _fileName: string;
  _downloadCount: string;
  _releaseNotes: string;
  _buildDate: string;
}
